import React ,{Component} from "react";
import {Route,Redirect} from 'react-router-dom';
// import {isAuthenticated} from "./index";

// const PrivateRoute=({componet:Component, ...rest})=>(
//     <Route 
//     {...rest}
//     render={props=>
//     isAuthenticated() ? (

//         <Component {...props} />
//     ): (
//         <Redirect to= {{
//             pathname:"/SignIn",
//             state:{from:props.location}
//         }}
//         />
//     )
    
    
//     }
// />
// );
function PrivateRoute({component,...rest}){
    
    
    let RenderComponents=component;
    let hasToken=JSON.parse(localStorage.getItem('jwt'));
    
    
     return <div>
          
          <Route 
          {...rest}
             
             render={
                 props=>{
 
                  return hasToken!==null ?
                     (<RenderComponents {...props}/>):(<Redirect to={{
                         pathname:'/SignIn'
                     }}/>)
                 }
             }
 
          
          />
     </div>
 }



export default PrivateRoute;

