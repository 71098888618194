import React,{useState,useEffect} from "react";
import axios from "axios";
import {API} from "../config";

function Home(){

  const[final,setFinal]=useState([]);

  useEffect(()=>{
    
    axios({
        method: 'get',
        url: `${API}/addserver`,
        headers:{'auth':`${JSON.parse(localStorage.getItem('auth'))}`}
         
       }
          
      ) 
        .then(res=>{
          console.log(res);
                setFinal(res.data);
              
    
                
            
        })
        .catch(err=>{
  
        })
  }, [final])



    return (
      


        <div className="col-12">
        <h1> Server Details</h1>
        <table class="table table-bordered">
        <thead class="thead-dark">
          <tr>
            <th scope="col">S.No</th>
            <th scope="col">Project Name </th>
            <th scope="col">Domain Name </th>
            <th scope="col">Domain Server Name</th>

            <th scope="col">Domain Expiry </th>
            <th scope="col">Hosting Server Name </th>
            <th scope="col">Hosting Server Expiry </th>


          </tr>
        </thead>
        <tbody>
                   
            {final.map((project,index)=>{
     return ( 
      
     
      <tr> <th scope="row">{index+1}</th><th scope="row">{project.projectName}</th>
      <th scope="row">{project.domainName}</th>
      <th scope="row">{project.domainServerName}</th>

      <th scope="row">{project.domainExpiry}</th>
      <th scope="row">{project.hostingName}</th>
      <th scope="row">{project.hostingExpiry}</th>

      
      </tr>
     
       )
    })}
            
        
          
        </tbody>
      </table>
      
      
      </div>


    )
}

export default Home;