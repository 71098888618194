import React,{useState,useEffect} from "react";
import axios from "axios";
import {API} from "../config";
import {Link,withRouter} from 'react-router-dom';


function Addproject(){
  const[result,setResult]=useState([]);
  const[value,setValue]=useState({
    clientName:"",
    projectName:""
  })

 
function handleproject(event){
  // const {name,value}=event.target
  const {name,value}=event.target;
  setValue(prevValue=>{
    return {
        ...prevValue,
        [name]:value
    };
})




}





  useEffect(()=>{
    
    axios({
        method: 'get',
        url: `${API}/clients`,
        headers:{'auth':`${JSON.parse(localStorage.getItem('auth'))}`}
         
       }
          
      ) 
        .then(res=>{
                setResult(res.data);
              
    
                
            
        })
        .catch(err=>{

        })
}, [result])

function handleClick(event){
  axios({
   method: 'POST',
   url: `${API}/projects`,
   data: {
    clientName:value.clientName,
    projectName:value.projectName

     
   }
       
 }).then(res=>{
   // console.log(res.data.client.name);
   // setResult(res.data.client.name);
 })
//  event.preventDefault();
 setValue({
   clientName:"",
   projectName:""
 }); 
}



    return(
      <div>
      <h1>{value.projectName}</h1>
      <h1>{value.clientName}</h1>

        <form className="form-signin">
        <h1 className="h3 mb-3 font-weight-normal"> ADD PROJECT</h1>
      <div class="form-group">
    <label for="exampleFormControlSelect1">Select Client:</label>
    <select onChange={handleproject} value={value.clientName} name="clientName"  className="form-control" id="exampleFormControlSelect1">
    {result.map((client,index)=>{
     return (
       
      
      <option > {client.name}</option>
      
       )
    })}
    </select>
  </div>
  <div class="form-group">
  <label for="exampleFormControlSelect1">Project Name: </label>
   <input onChange={handleproject} name="projectName" value={value.projectName} type="text"  className="form-control" placeholder="Project Name" required />
   </div>
   <Link to="/addserver">


        <button onClick={handleClick} className="btn btn-lg btn-primary btn-block" type="submit">ADD PROJECT</button>
        </Link>

      </form>
      <ul>
      
                </ul>
                </div>
    )
}

export default Addproject;