import React,{useState,useEffect} from "react";
import axios from "axios";
import {API} from "../config";
    import {Link,withRouter} from 'react-router-dom';
import Signin from "../dashboard/SignIn";


function Signup(){
    const[user,setUser]=useState({
        name:"",
        email:"",
        password:"",
        error:"",
        success:false
    });
    
    function handleUser(event){

        const{name,value}=event.target;

        setUser(prevValue=>{
            return {
                ...prevValue,
                [name]:value,
                error:""
            };
        })
       
     }
function handleSign(event){

     axios({
        method: 'POST',
        url: `${API}/signUp`,
        data: {
        name:user.name,
        email:user.email,
        password:user.password
               
        }
            
      })
      
      .then(res=>{
        setUser(prevValue=>{
            return {
                ...prevValue,
               name:"",
               email:"",
               password:"",
               error:"",
                success:true
                
            };
        })
      }).catch(error=>{

        if(error){

            setUser(prevValue=>{
                return {
                    ...prevValue,
                    error:error.response.data.error,
                    success:false
                    
                };
            })
            

          }
          
      })
     
       

      event.preventDefault();

      
  }

    function showError(){
        return <div className="alert alert-danger" style={{display:user.error?"":"none"}}>

     {user.error}  
      </div>
        
    }
    function showSuccess(){
        return (<div className="alert alert-info" style={{display:user.success?"":"none"}}>

        New Account is created. <Link to ="/Signin">Please SignIn </Link>
        </div>
        )
    }


    





      return (
          <div>
          {showSuccess()}
          {showError()}
        
     
        <form  className="form-signin">
 
       
    <label for="exampleInputEmail1">Name :</label>
    <input  name ="name" value={user.name} onChange={handleUser} type="name" class="form-control " required />
  
  
    <label for="exampleInputEmail1">Email :</label>
    <input name ="email" value={user.email} onChange={handleUser} type="email" class="form-control" required  />
 
  
    <label for="exampleInputEmail1">Password :</label>
    <input name ="password"value={user.password}  onChange={handleUser} type="password" class="form-control" required />
 
 
 
  <button onClick={handleSign} type="submit" class="btn btn-primary">Signup</button>
  
</form>
</div>
 




    )
}

export default Signup;