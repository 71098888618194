import React from "react";
import {BrowserRouter,Switch,Route} from 'react-router-dom';
import PrivateRoute from "./auth/Protected";

import Addclient from './dashboard/Addclient';
import Addproject  from './dashboard/Addproject';
import Addserver from './dashboard/Addserver';
import Signup from './dashboard/Signup';
import Home from './core/Home';
import Menu from './core/Menu';
import Signin from "./dashboard/SignIn";



function Routes(){
    return (
        <div>
        <BrowserRouter>
        <Menu />

       
            <Switch>

                <PrivateRoute  path="/" exact component={Home}/>
                <Route  path="/Signup" exact component={Signup}/>

                <Route  path="/SignIn" exact component={Signin}/>
                <PrivateRoute path="/addclient" exact component={Addclient}/>
                <PrivateRoute path="/addproject" exact component={Addproject}/>
                <PrivateRoute  path="/addserver" exact component={Addserver}/>



            </Switch>

        </BrowserRouter>
        </div>


    )

}
export default Routes;