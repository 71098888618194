import React,{useState,useEffect} from "react";
import axios from "axios";
import {API} from "../config";
import {Link,withRouter,Redirect} from 'react-router-dom';


function Signin(){
    const[loguser,setLogUser]=useState({
        
        email:"",
        password:"",
        err:"",
        redirectRefer:"",
        success:""
    });
    
    function handleLogUser(event){

        const{name,value}=event.target;
        setLogUser(prevValue=>{
          return {
              ...prevValue,
              
              [name]:value
          };
      })
     }

    //  Set local storage data 
const authenticate=(data,next)=>{
if(typeof window !== "undefined"){
    localStorage.setItem("jwt",JSON.stringify(data));
    next();
}



};
   
function handleLogin(event){
  event.preventDefault();

     axios({
        method: 'POST',
        url: `${API}/signIn`,
        data: {
                email:loguser.email,
        password:loguser.password
               
        }
            
      }).then(res=>{
        authenticate(res.data,()=>{
          setLogUser({
    
            email:"",
            password:"",
            redirectRefer:true
              
              }); 

      })
      }).catch(error=>{
        console.log(error.response.data.error);
       
        setLogUser({
    
          err:error.response.data.error
          
            
            }); 
       
      
        
      });
    
    
      
    }
    const redirectUser=()=>{
      if(loguser.redirectRefer){
        return <Redirect to="/"  />
      }
    }
    function showError(){
      return <div className="alert alert-danger" style={{display:loguser.err?"":"none"}}>

   {loguser.err}  
    </div>
      
  }
    




    





      return (
          <div>
          {showError()}
        
     
        <form  className="form-signin">
      
    
  
    <label for="exampleInputEmail1">Email :</label>
    <input name ="email" value={loguser.email} onChange={handleLogUser} type="email" class="form-control"  required />
     <label for="exampleInputEmail1">Password :</label>
    <input name ="password"value={loguser.password}  onChange={handleLogUser} type="password" class="form-control"  required />
 
    
  <button onClick={handleLogin} type="submit" class="btn btn-primary">Signin</button>
   

</form>
{redirectUser()} 



</div>
 




    )
}

export default  Signin;