import React,{useState,useEffect} from "react";
import axios from "axios";
import {API} from "../config";
import {Link,withRouter} from 'react-router-dom';



function Addserver(){
  const[project,setProject]=useState([])
  const[server,setServer]=useState({
    projectName:"",
    domainName:"",
    domainServerName:"",
    domainExpiry:"",
    hostingName:"",
    hostingExpiry:"",


  })

  useEffect(()=>{
    
    axios({
        method: 'get',
        url: `${API}/projects`,
        headers:{'auth':`${JSON.parse(localStorage.getItem('auth'))}`}
         
       }
          
      ) 
        .then(res=>{
                setProject(res.data);
              
    
                
            
        })
        .catch(err=>{

        })
}, [project])



function handleChange(event){
  const{name,value}=event.target;
  setServer(prevValue=>{
    return {
        ...prevValue,
        [name]:value
    };
})

  
}
function handleServer(event){
  axios({
   method: 'POST',
   url: `${API}/addserver`,
   data: {
    projectName:server.projectName,
    domainName:server.domainName,
    domainServerName:server.domainServerName,
    domainExpiry:server.domainExpiry,
    hostingName:server.hostingName,
    hostingExpiry:server.hostingExpiry,

     
   }
       
 }).then(res=>{
   console.log(res);
   // console.log(res.data.client.name);
   // setResult(res.data.client.name);
 })
 event.preventDefault();
 setServer({
  projectName:"",
  domainName:"",
  domainServerName:"",
  domainExpiry:"",
  hostingName:"",
  hostingExpiry:"",
 }); 
}
  

    return (
      
        <form onSubmit ={handleServer} className="form-signin">
        <h1 className="h3 mb-3 font-weight-normal"> ADD SERVER</h1>
        
  <div class="form-group">
    <label for="exampleFormControlSelect1">Select Project:</label>
    <select onChange={handleChange} value={server.projectName} name="projectName" class="form-control" id="exampleFormControlSelect1">
    {project.map((project,index)=>{
     return (
     
      <option > {project.projectName}</option>
     
       )
    })}
   </select>
  </div>
  <div class="form-group">
    <label for="exampleFormControlSelect1">Domain Name :</label>
    <input type="text" onChange={handleChange} value={server.domainName} name="domainName" className="form-control" placeholder="Domain Name" required />
  </div>
  <div class="form-group">
    <label for="exampleFormControlSelect1">Domain Server Name :</label>
    <input type="text" onChange={handleChange} value={server.domainServerName} name="domainServerName" className="form-control" placeholder="Domain Server Name" required />
  </div>
  <div class="form-group">
    <label for="exampleFormControlSelect1">Domain Name Expiry:</label>
    <input type="Date" onChange={handleChange} value={server.domainExpiry} name="domainExpiry" className="form-control" placeholder="Domain Expiry" required />
  </div>
  <div class="form-group">
    <label for="exampleFormControlSelect1">Hosting Server:</label>
    <input type="text" onChange={handleChange} value={server.hostingName} name="hostingName"className="form-control" placeholder="Hosting Server " required />
  </div>
  <div class="form-group">
    <label for="exampleFormControlSelect1">Hosting Server Expiry:</label>
    <input type="Date" onChange={handleChange} value={server.hostingExpiry} name="hostingExpiry" className="form-control" placeholder="Hosting Expiry " required />
  </div>
  
  

        <button  className="btn btn-lg btn-primary btn-block" type="submit">ADD SERVER</button>
       

     
      </form>
      
    


    )
    


}

export default Addserver;