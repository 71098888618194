import React,{useState,useEffect} from "react";
import axios from "axios";
import {API} from "../config";
import {Link,withRouter} from 'react-router-dom';


function Addclient(){
 const[client,setClient]= useState("");
//  const[result,setResult]=useState([]);
 
  function handleInput(event){
    const value =event.target.value;
    setClient(value);
    console.log(client);

  }
  
  function handleClick(event){
     axios({
      method: 'POST',
      url: `${API}/clients`,
      data: {
          name:client,
  
        
      }
          
    }).then(res=>{
      // console.log(res.data.client.name);
      // setResult(res.data.client.name);
    })
    // event.preventDefault();
    setClient(""); 
  }
 

     
    return (
      <div>
        <form className="form-signin">
        <h1 className="h3 mb-3 font-weight-normal"> ADD CLIENT</h1>
        <div className="form-group">
        <label for="exampleFormControlSelect1">Client Name: </label>
        <input name ="clientname" onChange={handleInput} value={client} type="text" id="inputEmail" className="form-control" placeholder="Client Name " required autofocus />
        </div>
            
       <Link to="/addproject">
       
        <button  onClick={handleClick} className="btn btn-lg btn-primary btn-block" type="submit">ADD CLIENT</button>
        </Link>

      </form>
     
      
      

      </div>
)

   
}

export default Addclient;