import React from "react";
import {Link,withRouter} from 'react-router-dom';

function isActive(history,path){

    if(history.location.pathname===path){
        return {color:"#000"};
    }
    else{
        return {color:"#fff"};
    }

};



function Menu({history}){
    return (
    <div>
        <ul className="nav nav-tabs bg-primary">
            <li className="nav-item">
                <Link className="nav-link" style={isActive(history,"/")} to="/">
                Home
                </Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link" style={isActive(history,"/addclient")} to="/addclient">
                Add Client
                </Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link" style={isActive(history,"/addproject")} to="/addproject">
                Add Projects
                </Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link" style={isActive(history,"/addserver")} to="/addserver">
                Add Server
                </Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link" style={isActive(history,"/Signup")} to="/Signup">
                        Signup                </Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link" style={isActive(history,"/Signin")} to="/Signin">
                Signin
                </Link>
            </li>



        </ul>
    </div>
    )

}
export default withRouter(Menu);