import React from 'react';
import ReactDOM from 'react-dom';

import Routes from "./Routes";
import Signup from './dashboard/Signup';


ReactDOM.render(
  <div>
 
  
    <Routes />
    </div>

  ,
  document.getElementById('root')
);


